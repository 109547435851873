import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import Navbar from "../../components/navbar/Navbar";

const FrontendGenerator = () => {
  const [demand, setDemand] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showCode, setShowCode] = useState(false);
  const iframeRef = useRef(null);

  const handleGenerate = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setGeneratedCode('');
    setShowCode(false);

    try {
      const response = await fetch('https://web-production-5ee8.up.railway.app/echidna/generateFrontend', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ demand }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate frontend');
      }

      const data = await response.json();
      
      if (!data || !data.generatedCode) {
        throw new Error('Invalid response format from the server');
      }

      setGeneratedCode(data.generatedCode);

    } catch (err) {
      console.error('Error generating frontend:', err);
      setError(`Error generating frontend: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [demand]);

  useEffect(() => {
    if (generatedCode && iframeRef.current) {
      const iframe = iframeRef.current;
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      const safeGeneratedCode = generatedCode.replace(/^function\s+\w+\s*\(/, 'function GeneratedComponent(');

      iframeDoc.open();
      iframeDoc.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <script src="https://unpkg.com/react@17/umd/react.development.js"></script>
            <script src="https://unpkg.com/react-dom@17/umd/react-dom.development.js"></script>
            <script src="https://unpkg.com/babel-standalone@6/babel.min.js"></script>
            <script src="https://cdn.tailwindcss.com"></script>
          </head>
          <body>
            <div id="root"></div>
            <script type="text/babel">
              ${safeGeneratedCode}
              ReactDOM.render(React.createElement(GeneratedComponent), document.getElementById('root'));
            </script>
          </body>
        </html>
      `);
      iframeDoc.close();
    }
  }, [generatedCode]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-black text-white font-sans flex flex-col">
      <header className=" bg-opacity-50 p-4">
        <Navbar />
      </header>

      <main className="flex-grow flex flex-col items-center justify-center p-4">
        {!generatedCode ? (
          <div className="max-w-2xl w-full space-y-8 text-center">
            <div>
              <h2 className="text-2xl font-semibold mb-2">Welcome to Echidna dev</h2>
              <p className="text-gray-300">Describe your desired frontend component, and we'll generate it for you.</p>
            </div>
            
            <textarea
              value={demand}
              onChange={(e) => setDemand(e.target.value)}
              placeholder="e.g., Create a responsive navbar with a logo, menu items, and a search bar"
              className="w-full p-4 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-white placeholder-gray-500 transition-all duration-300 ease-in-out"
              rows="4"
            />
            
            <button
              onClick={handleGenerate}
              disabled={isLoading || !demand}
              className={`w-full bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out transform hover:bg-blue-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
                isLoading || !demand ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Generating...
                </span>
              ) : (
                'Generate Frontend'
              )}
            </button>
          </div>
        ) : (
          <div className="w-full max-w-6xl space-y-8">
            <h2 className="text-2xl font-semibold text-center">Generated Component</h2>
            <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg">
              <iframe
                ref={iframeRef}
                title="Component Preview"
                className="w-full h-[600px] border-0"
              />
            </div>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setShowCode(!showCode)}
                className="bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out"
              >
                {showCode ? 'Hide Code' : 'Show Code'}
              </button>
              <button
                onClick={() => setGeneratedCode('')}
                className="bg-gray-700 text-white font-bold py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300 ease-in-out"
              >
                Generate Another Component
              </button>
            </div>
            {showCode && (
              <div className="bg-gray-800 rounded-lg p-4 overflow-x-auto">
                <pre className="text-sm">
                  <code className="text-green-400">{generatedCode}</code>
                </pre>
              </div>
            )}
          </div>
        )}
      </main>

      {error && (
        <div className="fixed bottom-4 right-4 bg-red-600 text-white p-4 rounded-lg shadow-lg" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default FrontendGenerator;