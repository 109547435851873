import { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../components/navbar/Navbar';
import { useSelector } from 'react-redux';
import './ViewProduct.css';
import vr from '../../assets/ContentImages/virtual-reality.png';

function TableRow({ product }) {
  return (
    <tr>
      <td>
        <label>
          <input type="checkbox" className="checkbox" />
        </label>
      </td>
      <td>
        <div className="flex items-center gap-3">
          <div className="avatar">
            <div className="mask mask-squircle w-11 h-11">
              <img src={vr} alt={product.fileName} />
            </div>
          </div>
          <div>
            <div className="font-bold">{product.fileName}</div>
          </div>
        </div>
      </td>
      <td>
      https://www.metabees.in/{product.fileName}
        <br />
      </td>
      <td>
        {product.status}
      </td>
      <td>
        {product.type}
      </td>
      <td>
        {product.hosting}
      </td>
      <td>
      <button className="btn btn-ghost btn-xs bg-red-500 text-white hover:bg-red-600">Discontinue</button>
      </td>
    </tr>
  );
}

const ViewProduct = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const formData = new FormData();
      formData.append('userId', userInfo._id);

      try {
        const response = await axios.post("https://web-production-5ee8.up.railway.app/products/userproducts", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + userInfo.token,
          },
        });

        if (response.status === 200) {
          setProducts(response.data);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [userInfo._id, userInfo.token]);

  return (
    <div className="min-h-screen w-full p-0 bg-black">
      <Navbar />
      <div className="p-20">
        <div className="overflow-x-auto">
          <table className="table w-full">
            <thead>
              <tr>
                <th>
                  <label>
                    <input type="checkbox" className="checkbox" />
                  </label>
                </th>
                <th>Product Name</th>
                <th>URL</th>
                <th>Status</th>
                <th>Type</th>
                <th>Server</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <TableRow key={index} product={product} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ViewProduct;
