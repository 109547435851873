import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import Navbar from './components/navbar/Navbar'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import SignUp from './pages/signup/SignUp'
import LogIn from './pages/login/LogIn'
import ESuccess from './pages/paymentsucess/esuccess'
import Home from './pages/home/Home'
import View from './pages/view/View'
import About from './pages/about/About'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import Echdinachat from './pages/cyberlake/echidnachat'
import Echidnamail from './pages/cyberlake/echidnamail'
import PasswordReset from './pages/PasswordReset/PasswordReset'
import Waitlist from './pages/waitlist/Waitlist'
import Contact from './pages/contact/Contact'
import Dashboard from './pages/dashboard/Dashboard'
import Upload from './pages/addproduct/Upload'
import ViewProduct from './pages/viewproduct/ViewProduct'
import Market from './pages/marketplace/Market'
import Preview from './pages/previewobj/prev'
import Published from './pages/Published/Published'
import ViewObject from './pages/VR/vr'
import {NextUIProvider} from "@nextui-org/system";
import ARScene from './pages/AR/ar'
import Arsettings from './pages/arSettings/arsettings'
import Cyberdash from './pages/cyberlake/cyberlakedash'
import Cyberlakeupload from './pages/cyberlake/cyberupload'
import FuturisticCreditPage from './pages/cyberlake/addcredits'
import PricingCard from './pages/cyberlake/plans'
import PaymentPage from './pages/cyberlake/paymentpage'
import ThreeDViewer from './pages/cyberlake/cyberlake3d'
import PublicView from './pages/cyberlake/cyberpublicview'
import Cyberhome from './pages/cyberhome/cyberhome'
import Neptadash from './pages/neptaverse/neptadash'
import { Theme } from '@radix-ui/themes';
import Echidna from './pages/Androforce/echidna'
import BuyCredits from './pages/cyberlake/echidnacredits'
import PaymentPage5 from './pages/cyberlake/echpayments/5moplan'
import PaymentPage20 from './pages/cyberlake/echpayments/20moplan'
import PaymentPage150 from './pages/cyberlake/echpayments/150moplan'
import ProspectPage from './pages/cyberlake/cyberpro1'
import FrontendGenerator from './pages/neptaverse/codegen'


function App() {
  return (
    <NextUIProvider>
      <Theme>
    <Router>
      
      
      <Routes>
        <Route path="/" element={<Home />} />
       
        <Route path="/view" element={<View />} />
        <Route path="/contact" element={<Waitlist />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="vr/:objectname" element={<ViewObject />} />
        <Route path ="ar/:objectname" element={<ARScene />} />
        <Route path="/cyberlake/:objectname" element={<PublicView />} />
        <Route path="/cyberlake" element={<Cyberhome />} />
        <Route path="/neptaverse" element={<Neptadash />} />
        <Route path='/echidna' element={<Echidna />} />
        
        
        <Route
          path="/auth/reset-password/:id/:token"
          element={<PasswordReset />}
        />
        <Route path="*" element={<Navigate to="/login" replace />} /> 

        <Route path="" element={<PrivateRoute />}>
           {/* <Route path="/dashboard" element={<Dashboard />} /> */}
  {/* <Route path="/Arsettings" element={<Arsettings />} /> */}
  {/* <Route path="/dashboard/upload" element={<Upload />} /> */}
  {/* <Route path="/viewproduct" element={<ViewProduct />} /> */}
  {/* <Route path="/marketplace" element={<Market />} /> */}
  {/* <Route path="/preview/:token" element={<Preview />} /> */}
  {/* <Route path="/published" element={<Published />} /> */}
          <Route path="/dashboard" element={<Cyberdash />} />
          <Route path="/echidnamail" element={<ProspectPage />} />
          <Route path="/cyberlake/upload" element={<Cyberlakeupload />} />
          <Route path="/cyberlake/credits" element={<FuturisticCreditPage />} />
          <Route path="/cyberlake/pricing" element={<PricingCard />} />
          <Route path="/cyberlake/chat" element={<Echdinachat />} />
          <Route path="/cyberlake/pay/:plan" element={<PaymentPage />} />
          <Route path="/cyberlake/privateview/:objectname" element={<ThreeDViewer />} />
          <Route path="/echidnamail/buycredits" element={<BuyCredits />} />
          <Route path="/cyberlake/echpayments/5moplan" element={<PaymentPage5 />} />
          <Route path="/cyberlake/echpayments/20moplan" element={<PaymentPage20 />} />
          <Route path="/cyberlake/echpayments/150moplan" element={<PaymentPage150 />} />
          <Route path= "/echidnamail/${token}"   element={<ESuccess />} />
          <Route path="/echidna/codegenv0" element={<FrontendGenerator />} />
      

         
          
          
        </Route>
      </Routes>
    </Router>
    </Theme>
    </NextUIProvider>
  )
}

export default App
